import React from "react"
import { navigate } from "gatsby"
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {AppBar, Toolbar, Typography, Button, Grid} from '@material-ui/core';
import Logo from  '../components/sitimm_logo'
import { Helmet } from "react-helmet"

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      '@media (max-width: 813px)': {
        display:"none"
      },
    },
    title: {
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      height:"100%",
      '@media (max-width: 477px)': {
        display:"none"
      },
    },
    Navbar: {
      minWidth: '100%',
      width: '100%',
      minHeight:"9vh",
      height: '9vh',
      backgroundColor:'#ad1d21',
     //'@media (max-width: 813px)': {
      //height: '8vh',
      //width: '100vw',
    //},
    },
    img: {
      width: '8vh',
      height: '8vh',
      position: 'absolute',
      //left: '50%', 
      //top: '50%',
      left: "7%",
      cursor: "pointer",
      transform: 'translate(50%, -0%)', //Cambiar a 50% si no se cambia las dimenciones de la imagen
      //display: 'inline',
      //'@media (max-width: 813px)': {
       //display:"none"
      //},
    },
    button: {
      textTransform: 'none',
      height:"8vh",
      color:"white",
      fontWeight: "bold",
      //borderLeft: "1px solid #38546d",
      //borderRight: "1px solid #16222c",
      '@media (max-width: 477px)': {
        display:"none"
      },
    }
  }));

export default ({children})=>{
    const classes = useStyles();   
    const theme = createMuiTheme({
  overrides: {
    MuiToolbar: {
      regular: {
        //minWidth: '500px',
        //minHeight: "32px",
        '@media(min-width:600px)' : {
          //minHeight:"48px",
          //minWidth: '500px',
        },
      }
    }
  }
});

const handleClick= link => () => {
  navigate(link)
}
    return(
    <MuiThemeProvider theme={theme}>
    <div> 
    <Helmet>
          <meta charSet="utf-8" />
          <title>SITIMM</title>
    </Helmet>
    <div position="static"  className={classes.Navbar}  >
    <div /* className={classes.Navbar} */>
      <Grid container direction="row" justify="space-between" >
        <Grid  item xl={2} >
          {/* <Typography variant="h6" className={classes.title}>
            SITIMM
          </Typography> */}
        </Grid>
        <Grid item xl={4}>
        <Typography onClick={handleClick('/')} className= {classes.img}>
         <Logo/>
       </Typography>
        </Grid>
        <Grid item xl={6}>
        {/*   <Button className={classes.button} onClick={handleClick('/')}>Inicio</Button> */}
          <Button className={classes.button} onClick={handleClick('/Comunicados')}>Comunicados</Button>
          <Button className={classes.button} onClick={handleClick('/programas')}>Programas</Button>
          <Button className={classes.button} onClick={handleClick('/revistas')}>Revistas</Button>
          <Button className={classes.button} onClick={handleClick('/eventos')}>Calendario</Button>
          {/* <Button className={classes.button} onClick={handleClick('/descuentos')}>Descuentos</Button> */}
          {/* <Button className={classes.button} onClick={handleClick('/tickets')}>Consultas</Button> */}
          <Button className={classes.button} onClick={handleClick('/articulo')}>Blog</Button>
          <Button className={classes.button} onClick={handleClick('/preguntas_frecuentes')}>Preguntas Frecuentes</Button>
        </Grid>
      </Grid>
    </div>
  </div>
        {children}
    </div>
    </MuiThemeProvider>)
}
