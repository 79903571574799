import api from "../baseurl";

const Get = async () => {
    
    const status = await api.post("visits/get").json();
    return status ;
    
  };
  
  export default Get ; 
