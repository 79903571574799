import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
//import { makeStyles} from '@material-ui/core/styles';
import { Typography, Grid, Input, Modal } from "@material-ui/core"
//import Facebook from '../images/facebook.svg'
import { Facebook, Twitter, YouTube } from "@material-ui/icons"
import cefomaIMG from "../images/ceforma.png"
import bibliotecaIMG from "../images/BIBLIOTECA.png"
//import Map from './map'
import { withStyles } from "@material-ui/core/styles"
import GetVisits from "../api/visits/get"
import SetVisits from "../api/visits/add"
import TextField from "./customTextfield"

import EmailSend from "../api/contacto/send"

const styles = theme => ({
  footer: {
    //flexGrow: 1,
    width: "100%",
    //height: '30vh',
    minWidth: "500px",
    backgroundColor: "#2f3640",
    position: "relative",
    fontFamily: "Montserrat !important",
    left: "0px",
    bottom: "0px",
    marginTop: "10px",
    color: "white",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  siteMap: {
    width: "100%",
    height: "100%",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  socialMedia: {
    color: "white", //'#212529',
    width: "100%",
    height: "100%",
    fontFamily: "Montserrat !important",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  siteMapText: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    color: "white", //"#212529",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  siteMapCont: {
    columnCount: 3,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    display: "flex",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  form: {
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%",
    "@media (max-width: 477px)": {},
  },
  formTitle: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    color: "white",
    fontFamily: "Montserrat !important",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  TextField: {
    width: "95%",
    color: "white !important", //"#212529",
    fontFamily: "Montserrat !important",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  text: {
    fontSize: 15,
    fontFamily: "Montserrat !important",
    textAlign: "center",
  },
  multilineColor: {
    color: "#FFFFFF",
  },
  s: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    color: "white", //"#212529",
    height: "50%",
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  customButton: {
    boxShadow: "inset 0px 1px 0px 0px #54a3f7",
    background: "linear-gradient(to bottom, #007dc1 5%, #0061a7 100%)",
    backgroundColor: "#007dc1",
    borderRadius: "3px",
    border: "1px solid #124d77",
    display: "inline-block",
    cursor: "pointer",
    color: "#ffffff",
    //fontFamily:'Arial',
    fontSize: "13px",
    padding: "6px 24px",
    textDecoration: "none",
    textShadow: "0px 1px 0px #154682",
    "&:hover": {
      background: "linear-gradient(to bottom, #0061a7 5%, #007dc1 100%)",
      backgroundColor: "#0061a7",
    },
    "&:active": {
      position: "relative",
      top: "1px",
    },
    "@media (max-width: 477px)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  power:{
    fontSize: 10,
    fontFamily: "Montserrat !important",
    textAlign: "center",

  }
})

class footer extends React.Component {
  constructor() {
    super()
    // No hagas esto!
    this.state = {
      name: "",
      phone: "",
      email: "",
      body: "",
      visits: 0,
      modalOpen: false,
      operationStatus: false,
    }
  }
  sendMail = async () => {
    try {
      await EmailSend({
        email: this.state.email,
        message: `Contacto desde Pagina SITIMM de ${this.state.name}, de numero ${this.state.phone} con su mensaje ${this.state.body}`,
      })
      console.log("send")
      this.setState({ operationStatus: true, modalOpen: true })
    } catch (error) {
      console.log(error)
      this.setState({ operationStatus: false, modalOpen: true })
    }
  }
  async componentDidMount() {
    await SetVisits()
    const visits = await GetVisits()
    this.setState({ visits: visits.visits ? visits.visits : "Cargando..." })
  }
  render() {
    const { classes } = this.props
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.footer}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.formTitle}
        >
          {/* <Grid item xs={2} className={classes.siteMap}>
              <Typography className={classes.siteMapText}>SiteMap</Typography>
                  
                      
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/tickets'>Consultas</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/'>Inicio</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/articulo'>Blog</Link></Typography>
                  
                      
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/programas'>Programas</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/revistas'>Revistas</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/eventos'>Eventos</Link></Typography>
                    
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/descuentos'>Descuentos</Link></Typography>
                      
              </Grid> */}
          <Grid
            item
            xs={2}
            container
            justify="start"
            alignItems="start"
            direction="column"
            className={classes.socialMedia}
          >
            <Typography className={classes.formTitle}>
              {" "}
              Nuestras Redes Sociales
            </Typography>
            <Grid>
              <Grid item className={classes.s}>
                <a
                  href="https://www.facebook.com/SITIMM-779875035474175/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Facebook style={{ fontSize: 40 }} />
                </a>
                <a
                  href="https://twitter.com/sitimm_ctm?lang=es"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Twitter style={{ fontSize: 40 }} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCe72e-5oBMwf-FPARAzRg9Q"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <YouTube style={{ fontSize: 40 }} />
                </a>
              </Grid>
              <Typography className={classes.formTitle}>
                {" "}
                Páginas asociadas
              </Typography>
              <Grid item className={classes.s}>
                <a
                  href="http://www.ceforma.com.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <img src={cefomaIMG} style={{ width: 53 }} />
                </a>
                <a
                  href="http://www.bibliotecaformativa.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit"}}
                >
                  <img
                    src={bibliotecaIMG}
                    alt="Biblioteca"
                    style={{ width: 53 }}
                  />
                </a>
              </Grid>
              <Grid item className={classes.s}>
              <Link to="/padron/" className={classes.link}>Acceso RH </Link> 
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="center"
            alignItems="center"
            className={classes.form}
          >
            <Typography className={classes.formTitle}>Contacto</Typography>

            <Grid direction="row" container justify="start" alignItems="start">
              <Grid
                direction="column"
                item
                xs={6}
                justify="start"
                alignItems="start"
              >
                <TextField
                  inputProps={{
                    className: classes.multilineColor,
                  }}
                  label="Nombre:"
                  onChange={event => {
                    this.setState({ name: event.target.value })
                  }}
                  value={this.state.name}
                />
                <TextField
                  label="Email:"
                  className={classes.TextField}
                  onChange={event => {
                    this.setState({ email: event.target.value })
                  }}
                  value={this.state.email}
                />
                <TextField
                  label="Telefono:"
                  className={classes.TextField}
                  onChange={event => {
                    this.setState({ phone: event.target.value })
                  }}
                  value={this.state.phone}
                />
              </Grid>
              <Grid
                direction="column"
                item
                xs={5}
                container
                justify="center"
                alignItems="center"
              >
                <TextField
                  label="Mensaje:"
                  variant="outlined"
                  className={classes.TextField}
                  rows="4"
                  multiline
                  inputProps={{
                    classes: classes.multilineColor,
                  }}
                  onChange={event => {
                    this.setState({ body: event.target.value })
                  }}
                  value={this.state.body}
                ></TextField>
                <spam
                  onClick={() => this.sendMail()}
                  className={classes.customButton}
                >
                  Enviar
                </spam>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Typography className={classes.formTitle}>
              Nuestras Oficinas
            </Typography>
            <Grid className={classes.text}>
              <a
                href="https://goo.gl/maps/qc9DSwMQbCW59mGU6"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className={classes.text}>
                  SITIMM IRAPUATO Tel: (462) 626 06 97 y (462) 626 25 50
                </Typography>
              </a>
              <a
                href="https://goo.gl/maps/PuMdminZ5cuvLcpN8"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className={classes.text}>
                  SITIMM SILAO Tel: (472) 722 35{" "}
                </Typography>
              </a>
              <a
                href="https://goo.gl/maps/Y7Tbg6iErCvNhAQT7"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className={classes.text}>
                  SITIMM CELAYA Tel: (461) 608 02 30
                </Typography>
              </a>
              <a
                href="https://goo.gl/maps/qbNt36DafBw1iEzU9"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className={classes.text}>
                  SITIMM VILLAGRÁN Tel: (411) 165 31 49
                </Typography>
              </a>
              <a
                href="https://goo.gl/maps/PJpqEdzAi4rkJgZ57"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className={classes.text}>
                  SITIMM SAN JOSÉ ITURBIDE Tel: (419) 198 25 51
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item justify="center" alignItems="center">
          <Typography className={classes.text}>
            Visitas: {this.state.visits}
          </Typography>
          <Typography className={classes.power}>
            Powered by Blue Hive©
          </Typography>
        </Grid>
        <Modal
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({ modalOpen: false })
          }}
          className={classes.modal}
        >
          <div className={classes.paper}>
            {this.state.operationStatus ? (
              <p>Su mensaje a sido enviado correctamente</p>
            ) : (
              <p>Ocurrió un error, inténtelo de nuevo</p>
            )}
          </div>
        </Modal>
      </Grid>
    )
  }
}

footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(footer)
