import ky from "ky"
const api = ky.create()

const send = async data => {
  const status = await api
    .post("https://formspree.io/f/xeqnodge", { json: { data } })
    .json()

  return status
}

export default send
